<template>
    <form>
    <div class="vx-row">
        <div class="vx-col w-full">
        <vs-input v-validate="{ required: true}" label="Question *" placeholder="Question" name="question" v-model="data.question" class="w-full" />
        <span class="text-danger text-sm"  v-show="errors.has('question')">{{ errors.first('question') }}</span>
        </div>
        <div class="vx-col mt-5 w-full">
        <label class="text-sm">Write Answer</label>
        <vs-textarea class="w-full" name="answer" v-validate="'max:4000'" counter="4000" label="Write Answer" :counter-danger.sync="counterDanger" v-model="data.answer" />
        <span class="text-danger text-sm"  v-show="errors.has('answer')">{{ errors.first('answer') }}
            </span>
        </div>
    </div>
    <vs-button class="ml-auto mt-2" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Add Question'}}</vs-button>
    </form>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            counterDanger: false,
            data: {
                product_id: this.$route.params.productId,
                question: "",
                answer: "",
            },
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/product-questions/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_QUESTION', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/product-questions/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_QUESTION', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
             this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>