<template>
  <div>
    <div class="mb-4">
      <product-requirements />
    </div>
    <div class="mb-4">
      <product-addons />
    </div>
    <div class="">
      <product-faqs />
    </div>
  </div>
</template>

<script>

import ProductFaqs from "./components/ProductFaqs.vue"
import ProductAddons from "./components/ProductAddons.vue"
import ProductRequirements from "./components/ProductRequirements.vue"

export default {
  components: {
    ProductFaqs,
    ProductAddons,
    ProductRequirements,
  },
}
</script>
