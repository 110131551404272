<template>
  <vx-card no-shadow>
    <vs-table stripe noDataText="" :data="[]">
      <template slot="thead">
        <vs-th>
        </vs-th>
        <vs-th>
          Basic
        </vs-th>
        <vs-th>
          Standard
        </vs-th>
        <vs-th>
          Premium
        </vs-th>
        <vs-th>
          Ultimate
        </vs-th>
      </template>

      <template>
        <vs-tr :key="-4">
          <vs-th>
            Package Name
          </vs-th>
          <vs-td>
            <vs-input v-validate="{ required: true}" placeholder="Package Name" 
            name="basic_name" v-model="data.basic.name" class="w-full" />
            <span class="text-danger text-10"  v-show="errors.has('basic_name')"
            >{{ errors.first('basic_name') }}</span>
          </vs-td>
          <vs-td>
            <vs-input v-validate="{ required: true}" placeholder="Package Name" 
            name="standard_name" v-model="data.standard.name" class="w-full" />
            <span class="text-danger text-10"  v-show="errors.has('standard_name')"
            >{{ errors.first('standard_name') }}</span>
          </vs-td>
          <vs-td>
            <vs-input v-validate="{ required: true}" placeholder="Package Name" 
            name="premium_name" v-model="data.premium.name" class="w-full" />
            <span class="text-danger text-10"  v-show="errors.has('premium_name')"
            >{{ errors.first('premium_name') }}</span>
          </vs-td>
          <vs-td>
            <vs-input v-validate="{ required: true}" placeholder="Ultimate Name" 
            name="ultimate_name" v-model="data.ultimate.name" class="w-full" />
            <span class="text-danger text-10"  v-show="errors.has('ultimate_name')"
            >{{ errors.first('ultimate_name') }}</span>
          </vs-td>
        </vs-tr>

        <vs-tr :key="-3" >
          <vs-th>
            Price ($)
          </vs-th>
          <vs-td>
            <vs-input type="number" v-validate="{required: true,  regex: /^[0-9]+$/}" 
            name="basic_price" v-model="data.basic.price" class="w-full" placeholder="e.g 10" />
            <span class="text-danger text-10" v-show="errors.has('basic_price')">{{ errors.first('basic_price') }}</span>
          </vs-td>
          <vs-td>
            <vs-input type="number" v-validate="{required: true,  regex: /^[0-9]+$/}" 
            name="standard_price" v-model="data.standard.price" class="w-full" placeholder="e.g 10" />
            <span class="text-danger text-10" v-show="errors.has('standard_price')">{{ errors.first('standard_price') }}</span>
          </vs-td>
          <vs-td>
            <vs-input type="number" v-validate="{required: true,  regex: /^[0-9]+$/}" 
            name="premium_price" v-model="data.premium.price" class="w-full" placeholder="e.g 10" />
            <span class="text-danger text-10" v-show="errors.has('premium_price')">{{ errors.first('premium_price') }}</span>
          </vs-td>
          <vs-td>
            <vs-input type="number" v-validate="{required: true,  regex: /^[0-9]+$/}" 
            name="ultimate_price" v-model="data.ultimate.price" class="w-full" placeholder="e.g 10" />
            <span class="text-danger text-10" v-show="errors.has('ultimate_price')">{{ errors.first('ultimate_price') }}</span>
          </vs-td>
        </vs-tr>

          <vs-tr :key="-2">
            <vs-th>
              Delivery Time (days)
            </vs-th>
            <vs-td>
               <select v-model="data.basic.delivery_time"
                class="form-select" placeholder="--select day--">
                <option selected value="" >--select day--</option>
                <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                :value="dTime.value" >{{dTime.text}}</option>
              </select>
            </vs-td>
            <vs-td>
                <select v-model="data.standard.delivery_time"
                class="form-select" placeholder="--select day--">
                <option selected value="" >--select day--</option>
                <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                :value="dTime.value" >{{dTime.text}}</option>
              </select>
            </vs-td>
            <vs-td>
               <select v-model="data.premium.delivery_time"
                class="form-select" placeholder="--select day--">
                <option selected value="" >--select day--</option>
                <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                :value="dTime.value" >{{dTime.text}}</option>
              </select>
            </vs-td>
            <vs-td>
               <select v-model="data.ultimate.delivery_time"
                class="form-select" placeholder="--select day--">
                <option selected value="" >--select day--</option>
                <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                :value="dTime.value" >{{dTime.text}}</option>
              </select>
            </vs-td>
          </vs-tr>
          <vs-tr  :key="-1" >
            <vs-th>
              Revisions
            </vs-th>
            <vs-td>
              <vs-input type="text" v-validate="{required: true}" name="basic_revisions" 
              v-model="data.basic.revisions" class="w-full" placeholder="e.g: Unlimited" />
              <span class="text-danger text-10" v-show="errors.has('basic_revisions')">{{ errors.first('basic_revisions') }}</span>
            </vs-td>
            <vs-td>
              <vs-input type="text" v-validate="{required: true}" name="standard_revisions" 
              v-model="data.standard.revisions" class="w-full" placeholder="e.g: Unlimited" />
              <span class="text-danger text-10" v-show="errors.has('standard_revisions')">{{ errors.first('standard_revisions') }}</span>
            </vs-td>
            <vs-td>
              <vs-input type="text" v-validate="{required: true}" name="premium_revisions" 
              v-model="data.premium.revisions" class="w-full" placeholder="e.g: Unlimited" />
              <span class="text-danger text-10" v-show="errors.has('premium_revisions')">{{ errors.first('premium_revisions') }}</span>
            </vs-td>
             <vs-td>
              <vs-input type="text" v-validate="{required: true}" name="ultimate_revisions" 
              v-model="data.ultimate.revisions" class="w-full" placeholder="e.g: Unlimited" />
              <span class="text-danger text-10" v-show="errors.has('ultimate_revisions')">{{ errors.first('ultimate_revisions') }}</span>
            </vs-td>
          </vs-tr>
          <vs-tr  :key="-6" >
            <vs-th>
              Description
            </vs-th>
            <vs-td>
              <vs-textarea class="w-full" rows="3" name="basic_description" v-validate="'max:100'"
                counter="100" label="Write description"
                v-model="data.basic.description" />
                <span class="text-danger text-sm"  v-show="errors.has('basic_description')">{{ errors.first('basic_description') }}
                </span>
            </vs-td>
            <vs-td>
              <vs-textarea class="w-full" rows="3" name="standard_description" v-validate="'max:100'"
                counter="100" label="Write description"
                v-model="data.standard.description" />
                <span class="text-danger text-sm"  v-show="errors.has('standard_description')">{{ errors.first('standard_description') }}
                </span>
            </vs-td>
            <vs-td>
              <vs-textarea class="w-full" rows="3" name="premium_description" v-validate="'max:100'"
                 counter="100" label="Write description"
                 v-model="data.premium.description" />
                <span class="text-danger text-sm"  v-show="errors.has('premium_description')">{{ errors.first('premium_description') }}
                </span>
            </vs-td>
            <vs-td>
              <vs-textarea class="w-full" rows="3" name="ultimate_description" v-validate="'max:100'"
                 counter="100" label="Write description"
                 v-model="data.ultimate.description" />
                <span class="text-danger text-sm"  v-show="errors.has('ultimate_description')">{{ errors.first('ultimate_description') }}
                </span>
            </vs-td>
          </vs-tr>

        <template v-for="(attribute, indextr) in attributes">
          <vs-tr :key="indextr" v-if="attribute.type == 'input'" >
            <vs-th>
              {{attribute.name}}
            </vs-th>
            <vs-td>
              <vs-input placeholder="Enter value" v-model="data.basic.attributes[attribute.id]" class="w-full" />
            </vs-td>
            <vs-td>
              <vs-input placeholder="Enter value" v-model="data.standard.attributes[attribute.id]" class="w-full" />
            </vs-td>
            <vs-td>
              <vs-input placeholder="Enter value" v-model="data.premium.attributes[attribute.id]" class="w-full" />
            </vs-td>
            <vs-td>
              <vs-input placeholder="Enter value" v-model="data.ultimate.attributes[attribute.id]" class="w-full" />
            </vs-td>
          </vs-tr>

          <vs-tr :key="indextr" v-if="attribute.type == 'check'" >
            <vs-th>
              {{attribute.name}}
            </vs-th>
            <vs-td>
              <vs-checkbox v-model="data.basic.attributes[attribute.id]" vs-value="supported"></vs-checkbox>
            </vs-td>
            <vs-td>
              <vs-checkbox v-model="data.standard.attributes[attribute.id]" vs-value="supported"></vs-checkbox>
            </vs-td>
            <vs-td>
              <vs-checkbox v-model="data.premium.attributes[attribute.id]" vs-value="supported"></vs-checkbox>
            </vs-td>
            <vs-td>
              <vs-checkbox v-model="data.ultimate.attributes[attribute.id]" vs-value="supported"></vs-checkbox>
            </vs-td>
          </vs-tr>
        </template>

      </template>
    </vs-table>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click.prevent="submitPackages()" size="small">Submit Packages</vs-button>
       <vs-button class="ml-2 mt-2 bg-success" @click.prevent="resetData()" size="small">Reset</vs-button>
    </div>

  </vx-card>
</template>

<script>

export default {

  data() {
    return {
      data: {
        basic:{
          attributes:{}
        },
        standard: {
          attributes:{}
        },
        premium:{
          attributes:{}
        },
        ultimate:{
          attributes:{}
        }
      },
    }
  },
  computed: {
     product(){
      return this.$store.state.productList.product
    },
    packages(){
      return this.product.product_packages ?
        JSON.parse(JSON.stringify(this.product.product_packages)) : []
    },
    attributes(){
      return this.product.attributes ?
          this.product.attributes : []
    },
    deliveryDays(){
      let days = [{value: 24, text: "1 day"}];
      for (let step = 2; step < 101; step++) {
        days.push({value: step*24, text: `${step} days`});
      }
      return days;
    }
  },
  methods: {
    submitPackages(){
      this.$validator.validateAll().then(result => {
        if (!result || this.$route.params.productId < 1) {
          this.alertError("Please recheck form not valid!");return}
        let formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$vs.loading({color: "#444", type: "sound"})
        this.$http.post(`/packages/${this.$route.params.productId}/save`,formData)
        .then((response) => {
          this.$vs.loading.close()
          if(response.data.success){
            this.$store.commit("productList/SET_PACKAGES", response.data.data)
          }
        })
      })
    },
    parsePackages(){
      if(this.packages.length){
        this.packages.forEach((p)=> {
          if(["basic","standard","premium","ultimate"].includes(p.pack_id)){
            this.data[p.pack_id] = p;
          }
        });
      }
    },
    resetData(){
      this.data = {
        basic:{
          attributes:{}
        },
        standard: {
          attributes:{}
        },
        premium:{
          attributes:{}
        },
        ultimate:{
          attributes:{}
        },
      }
      this.$validator.reset();
    },
  },
  created(){
    this.parsePackages();
  }
}
</script>

<style type="text/css">
  .text-10{
    font-size: 10px !important
  }
</style>
