<template>
  <vx-card no-shadow>
    <vs-list>
      <vs-list-header icon-pack="feather" icon="icon-file-plus" title="Frequently Asked Questions">
      </vs-list-header>
    </vs-list>
    <vs-collapse type="default" accordion>
        <vs-collapse-item v-for="(question, index) in fAQuestions" :key="index">
            <div slot="header">{{question.question}}</div>
            <div>
                <p>{{question.answer}}</p>
            </div>
            <div class="flex justify-end">
              <vs-button radius color="dark" @click="editItem(question)"  class="inline" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
              <vs-button radius color="danger" @click="deleteItem(question.id)" class="inline" type="border" icon-pack="feather" icon="icon-trash"></vs-button>
            </div>
        </vs-collapse-item>
    </vs-collapse>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="mt-2 mr-2" @click="popupClone = true;" color="warning" size="small">Copy From</vs-button>
      <vs-button class="mt-2" @click="popupAddItem = true; editMode = false " size="small">Add Question</vs-button>
    </div>
    <vs-popup class="holamundo" @close="initializeAll" 
    :title="editMode ? 'Edit Question':'Add New Question'"
     :active.sync="popupAddItem">
      <product-faq-form v-if="popupAddItem"
        :editItem="showItem"
        :editMode="editMode"
        @closeMe="initializeAll"/>
    </vs-popup>

    <vs-popup class="holamundo" @close="initializeAll"
     title="Select product to copy from" 
     :active.sync="popupClone">
       <select v-model="cloneFrom"
          class="form-select mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, dpIndex) in products" :key="dpIndex"
          :value="dPro.id" >{{dPro.name}}</option>
      </select>
      <vs-button class="mt-2" @click="cloneNow()" size="small">Clone</vs-button>
    </vs-popup>
  </vx-card>
</template>

<script>
import ProductFaqForm from "@/components/forms/ProductFaqForm.vue"
export default {
  components:{
    ProductFaqForm
  },
  data() {
    return {
      popupAddItem: false,
      editMode: false,
      showItem: null,
      cloneFrom: null,
      popupClone: false
    }
  },
  computed: {
    fAQuestions(){
      return this.$store.state.productList.product.questions
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    editItem(item){
      this.editMode = true
      this.showItem =  {...item}
      this.popupAddItem = true
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete question',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.delete(`/product-questions/${itemId}/delete`)
      .then((response) =>{
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/DELETE_QUESTION", itemId)
        }
      })
    },
    cloneNow(){
      if(!this.cloneFrom){return}
      let data = {from: this.cloneFrom, to: this.$route.params.productId}
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post('/product-questions/clone',data)
      .then((response) =>{
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
          this.initializeAll()
        }
      })
    },
    initializeAll(){
      this.showItem = null
      this.editMode = false
      this.popupAddItem = false
      this.editMode = false;
      this.popupClone = false;
    }
  }
}
</script>
