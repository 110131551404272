<template>
  <vx-card no-shadow>
    <vs-list>
        <vs-list-header icon-pack="feather" icon="icon-codesandbox" title="Product attributes">
        </vs-list-header>
        <vs-list-item icon-pack="feather" icon="icon-check" v-for="(attribute,index) in attributes" :key="index" 
          :title="attribute.name" :subtitle="`Type: ${attribute.type}`">
        <vs-button radius color="dark" @click="editItem(attribute)"  class="inline" type="line" icon-pack="feather" icon="icon-edit"></vs-button>
        <vs-button radius color="danger" @click="deleteItem(attribute.id)" class="inline" type="border" icon-pack="feather" icon="icon-trash"></vs-button>

      </vs-list-item>
    </vs-list>

    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="mt-2 mr-2" @click="popupClone = true;" color="warning" size="small">Copy From</vs-button>
      <vs-button class="mt-2" @click="popupAddItem = true; editMode = false" size="small">Add Attribute</vs-button>
    </div>

    <vs-popup class="holamundo" @close="initializeAll" 
    :title="editMode ? 'Edit Attribute':'Add New Attribute'"
     :active.sync="popupAddItem">
      <product-attribute-form v-if="popupAddItem"
        :editItem="showItem"
        :editMode="editMode"
        @closeMe="initializeAll"/>
    </vs-popup>

    <vs-popup class="holamundo" @close="initializeAll"
     title="Select product to copy from" 
     :active.sync="popupClone">
       <select v-model="cloneFrom"
          class="form-select mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, dpIndex) in products" :key="dpIndex"
          :value="dPro.id" >{{dPro.name}}</option>
      </select>
      <vs-button class="mt-2" @click="cloneNow()" size="small">Clone</vs-button>
    </vs-popup>
  </vx-card>
</template>

<script>
import ProductAttributeForm from "@/components/forms/ProductAttributeForm.vue"
export default {
  components:{
    ProductAttributeForm
  },
  data() {
    return {
      popupAddItem: false,
      editMode: false,
      showItem: null,
      cloneFrom: null,
      popupClone: false
    }
  },
  computed: {
    attributes(){
      return this.$store.state.productList.product.attributes
    },
    products(){
      return this.$store.state.productList.products.map((p) => {return {id: p.id, name:p.name}})
    },
  },
  methods: {
    editItem(item){
      this.showItem =  {...item}
      this.editMode = true
      this.popupAddItem = true
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete an attribute!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$vs.loading({color: "#444", type: "sound"});
      this.$http.delete(`/attributes/${itemId}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit("productList/DELETE_ATTRIBUTE", itemId)
          this.initializeAll();
        }
      })
    },
    cloneNow(){
      if(!this.cloneFrom){return}
      let data = {from: this.cloneFrom, to: this.$route.params.productId}
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post('/attributes/clone',data)
      .then((response) =>{
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
          this.initializeAll()
        }
      })
    },
    initializeAll(){
      this.showItem = null;
      this.popupAddItem = false;
      this.editMode = false;
      this.popupClone = false;
    }
  },
}
</script>
