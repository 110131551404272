<template>
<form>
    <div class="vx-row">
        <div class="vx-col mb-5 w-full">
            <vs-input label="Name" placeholder="Name"
                name="name" v-model="data.name" class="w-full" />
        </div>
        <div class="vx-col mb-5 w-full">
            <vs-input v-validate="{ required: true}" label="Title (it should be the question)" placeholder="e.g What is your business name?"
                name="title" v-model="data.title" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>
        <div class="vx-col mb-5  w-full">
            <vs-select autocomplete v-validate="'required'" v-model="data.type" name="type"
                    placeholder="Select type" label="Type" class="select-large w-full">
                <vs-select-item v-for="(input, iIndex) of inputTypes" :key="iIndex"
                :value="iIndex" :text="input" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <div class="vx-col w-full flex items-end mb-5">
            <span class="mr-5 md:mr-8">
            <span class="text-sm">Required </span>
            <vx-tooltip text="Make it required or optional" position="right">
                <vs-switch v-model="data.required">
                <span slot="on">On</span>
                <span slot="off">Off</span>
                </vs-switch>
            </vx-tooltip>
            </span>
            <span class="mr-5 md:mr-8">
            <span class="text-sm">Multiple </span>
            <vx-tooltip text="Enable for multi-value selection" position="right">
                <vs-switch v-model="data.multiple">
                <span slot="on">On</span>
                <span slot="off">Off</span>
                </vs-switch>
            </vx-tooltip>
            </span>
            <!-- <span class="mr-5 md:mr-8">
            <span class="text-sm">Active</span>
            <vx-tooltip text="Enable it or disable" position="right">
                <vs-switch v-model="data.is_active">
                <span slot="on">On</span>
                <span slot="off">Off</span>
                </vs-switch>
            </vx-tooltip>
            </span> -->
        </div>
        <div v-if="data.required" class="vx-col mb-5 w-full">
            <vs-input v-validate="{ required: true}" label="Required text" placeholder="Enter required text"
                name="required_text" v-model="data.required_text" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('required_text')">{{ errors.first('required_text') }}</span>
        </div>
        <div class="vx-col w-full mb-3">
            <label class="text-sm">Descriptions</label>
            <vs-textarea class="w-full" rows="2" name="description" v-validate="'max:1000'" counter="1000" label="Write description"
               v-model="data.description" />
              <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}
              </span>
        </div>
    </div>
    <vs-button class="ml-auto" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Add Requirement'}}</vs-button>
</form>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                name: "",
                title: "",
                type: "",
                kind: "",
                required_text: "",
                required: false,
                multiple: false,
                is_active: true,
                sort_order: 0,
                description: "",
            },
        }
    },
    computed:{
        inputTypes(){
            return this.$store.state.productList.inputTypes
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/product-requirements/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_REQUIREMENT', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/product-requirements/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_REQUIREMENT', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
             this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>