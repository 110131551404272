<template>
  <div>
    <div v-if="!isLoading">
        <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
          <vs-tab icon-pack="feather" icon="icon-cpu" :label="!isSmallerScreen ? 'Overview' : ''">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <product-overview />
            </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-anchor" :label="!isSmallerScreen ? 'Attributes' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
              <product-attributes />
            </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-gift" :label="!isSmallerScreen ? 'Packages' : ''">
            <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
              <product-packages />
            </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-file-text" :label="!isSmallerScreen ? 'Descriptions' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
              <product-description />
            </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-settings" :label="!isSmallerScreen ? 'Settings' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
              <product-settings />
            </div>
          </vs-tab>
          <vs-tab icon-pack="feather" icon="icon-image" :label="!isSmallerScreen ? 'Portfolios' : ''">
            <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
              <product-files />
            </div>
          </vs-tab>
        </vs-tabs>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>
<script>

import ProductOverview from "@/components/product/ProductOverview.vue"
import ProductDescription from "@/components/product/ProductDescription.vue"
import ProductAttributes from "@/components/product/ProductAttributes.vue"
import ProductPackages from "@/components/product/ProductPackages.vue"
import ProductFiles from "@/components/product/ProductFiles.vue"
import ProductSettings from "@/components/product/ProductSettings.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components: {
    ProductOverview,
    ProductDescription,
    ProductAttributes,
    ProductPackages,
    ProductFiles,
    ProductSettings,
    IsLoading

  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    orderList(){
      return this.$store.getters["orderList/getProductOrders"](this.$route.params.productId)
    }
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategories")
    this.$store.dispatch("productList/fetchProducts")
    this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
    .then((response) => {
      this.isLoading = false
      if(!response.data.success){
        this.$router.push({path: '/error-404'})
      }
    })
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
