<template>
 <form>
    <div class="vx-row">
        <div class="vx-col lg:w-3/5 w-full">
        <vs-input v-validate="{ required: true}" label="Addon Name *" placeholder="Addon Name" name="addon_name" v-model="data.name" class="w-full" />
        <span class="text-danger text-sm"  v-show="errors.has('addon_name')">{{ errors.first('addon_name') }}</span>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
        <vs-input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" label="Charge *" name="charge" v-model="data.charge" class="lg:mt-0 mt-5 w-full" placeholder="e.g 100" />
        <span class="text-danger text-sm" v-show="errors.has('charge')">{{ errors.first('charge') }}</span>
        </div>
        <div class="vx-col mt-5 w-full">
        <vs-input label="Description " placeholder="Write description" name="description" v-model="data.description" class="w-full" />
        </div>
    </div>
    <vs-button class="ml-auto mt-2" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Add addon'}}</vs-button>
</form>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                product_id: this.$route.params.productId,
                name: "",
                charge: null,
                description: "",
            },
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/addons/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/ADD_ADDON', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/addons/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('productList/UPDATE_ADDON', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>