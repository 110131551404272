<template>
  <div>
    <div class="vx-row">
        <div class="vx-col mb-5 w-full">
             <transition name="fade" mode="out-in">
                <product-requirement-value-form v-if="enableForm" :value="showValue"
                :requirementId="requirement.id" :editMode="editMode" 
                @created="addItem" @updated="updateItem" @exit="resetForm()"/>
             </transition>
             <vs-button v-if="!enableForm" @click="enableForm = true "
              color="warning">Add new value</vs-button>
        </div>
    </div>
    <div v-if="!enableForm" class="vx-row mt-4">
        <div v-for="(value, tIndex) in requirement.values" 
          :key="tIndex" class="vx-col w-full md:w-1/2 mb-3">
            <div class="rounded bg-white p-2 cursor-pointer shadow border border-1">
                <div class="flex-grow-1 mb-2">
                    <p class="mb-1" style="font-size:10px;">Default: {{value.default}}</p>
                    <h5 class="mb-1 mt-0">{{value.name}}</h5>
                    <p style="word-break: break-all;font-size:12px;color:#666;" class="mb-0 small">{{value.value}}</p>
                </div>
                <div class="flex justify-end">
                    <span @click="initEdit(value)" class="mr-5 text-dark">Edit</span>
                    <span @click="deleteItem(value.id)" class="text-danger">Delete</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ProductRequirementValueForm from '@/components/forms/ProductRequirementValueForm.vue'
export default {
  components: { ProductRequirementValueForm },
    props:{
        showItem:{
            type: Object,
            required: true
        }
    },
    data(){
        return{
            enableForm:false,
            editMode: false,
            showValue:null,
            requirement:{}
        }
    },
    methods:{
        initEdit(value){
            this.showValue = {...value}
            this.enableForm = true
            this.editMode = true
        },
        addItem(item){
            this.requirement.values.unshift(item);
            this.resetForm()
        },
        updateItem(item){
            const dataIndex = this.requirement.values.findIndex((i) => i.id == item.id)
            Object.assign(this.requirement.values[dataIndex], item)
            this.resetForm();
        },
        deleteItem(valueId){
            if(!valueId){return}
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.delete(`/product-requirement-values/${valueId}/delete`)
            .then((response) =>{
                this.$vs.loading.close()
                if(response.data.success){
                    const ItemIndex = this.requirement.values.findIndex((item) => item.id == valueId)
                    this.requirement.values.splice(ItemIndex, 1);
                    this.resetForm();
                }
            })
        },
        resetForm(){
            this.$store.commit('productList/UPDATE_REQUIREMENT', JSON.parse(JSON.stringify(this.requirement)))
            this.editMode = false
            this.enableForm = false
            this.showValue = null
        }
    },
    mounted(){
       this.requirement = JSON.parse(JSON.stringify(this.showItem))
    }
}
</script>

<style>

</style>