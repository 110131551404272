<template>
  <vx-card no-shadow>
     <div class="vx-row">
        <div class="vx-col mb-5 sm:w-2/3 w-full">
            <form>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input v-validate="{ required: true, regex: /^[a-zA-Z0-9-&_() ]+$/}" label="Product Name *" placeholder="Product Name" name="name" v-model="data.name" class="mt-5 w-full" />
                <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-select autocomplete  v-validate="'required'" v-model="data.category_id" name="category" placeholder="--Choose Category--" label="Product Category *" class="select-large mt-5 w-full">
                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categoryList" />
                </vs-select>
                <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first('category') }}</span>
              </div>
              <div class="vx-col w-full">
                <vs-input v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" label="Slug *" name="slug" v-model="data.slug" class="mt-5 w-full" placeholder="Enter URL name" />
                <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
              </div>
              <div class="vx-col mt-5 w-full">
                <vs-input icon-pack="feather" icon="icon-dollar-sign" v-validate="{ required: true, regex: /^[0-9]+$/}" type="number"
                label="Price (dollar)*" name="price" v-model="data.price" class="w-full" placeholder="Price" />
                <span class="text-danger text-sm" v-show="errors.has('price')">{{ errors.first('price') }}</span>
              </div>
              <div class="vx-col w-full flex pt-6 items-end mb-6">
                <span class="mr-8">
                  <span class="text-sm"> Active</span>
                  <vx-tooltip text="ON for enable, OFF for disabled" position="right">
                    <vs-switch v-model="data.is_active">
                      <span slot="on">On</span>
                      <span slot="off">Off</span>
                    </vs-switch>
                  </vx-tooltip>
                </span>
                <span class="mr-8">
                  <span class="text-sm"> Featured</span>
                  <vx-tooltip text="ON for enable, OFF for disabled" position="right">
                    <vs-switch v-model="data.is_featured">
                      <span slot="on">On</span>
                      <span slot="off">Off</span>
                    </vs-switch>
                  </vx-tooltip>
                </span>
              </div>
            </div>
            <div class="flex flex-wrap items-center">
              <vs-button type="filled" size="small" @click.prevent="updateProduct" class="mt-2 block">Update</vs-button>
              <vs-button class="ml-4 mt-2" size="small" type="border" @click="fetchProduct()" color="success">Reset Data</vs-button>
            </div>
          </form>
        </div>
        <div class="vx-col w-full sm:w-1/3 mb-base">
            <product-image-update />
        </div>
     </div>
  </vx-card>
</template>

<script>
import ProductImageUpdate from "./components/ProductImageUpdate.vue"

export default {
  components:{
    ProductImageUpdate
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product;
    },
    categoryList() {
      return this.$store.state.categoryList.categories
    },
  },
  methods: {
    updateProduct() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({color: "#444", type: "sound"})
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/products/${this.$route.params.productId}/update`,formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('productList/UPDATE_PRODUCT', response.data.data)
            }
           })
        }
      })
    },
    fetchProduct() {
      if(this.$route.params.productId < 1 ) {return}
      this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
      .then((response) => {
        if(response.data.success){
          this.patchProduct()
        }
      })
    },
    patchProduct() {
      this.data = {
        name: this.product.name,
        slug: this.product.slug,
        category_id: this.product.category_id,
        is_active: this.product.is_active,
        is_featured: this.product.is_featured,
        price: this.product.price,
      }
    }
  },
  created(){
    this.patchProduct()
  },
}
</script>
